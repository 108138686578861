import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../components/store";
import PrivateRoute from "../../components/user-management/PrivateRoute";
import { addError } from "../../utils/errors";
import { urlService } from "../../_services";

const Page = () => {
  const [companies, setCompanies] = useState([] as any[]);
  const [, setContext] = useContext(Context);

  useEffect(() => {
    let url = urlService.getUrl(`/api/admin/companies`);

    /* download the filecontent */
    urlService
      .getAxios()
      .get(url)
      .then((res) => {
        if (res.data && res.data.companies) {
          setCompanies(res.data.companies);
        } else {
          if (res.data && res.data.message) {
            addError(setContext, res.data.message);
          } else {
            addError(setContext, "Error fetching Company data");
          }
        }
      })
      .catch((reason) => {
        if (reason.response) {
          addError(setContext, reason.response.data.message);
        } else {
          addError(setContext, "Error fetching Company data");
        }
      });
  }, []);

  return (
    <div>
      <PrivateRoute>
        {companies.map((company) => (
          <div>
            <span>Name: {company.name}</span>
            <span>Credit: {company.credit || 0}</span>
          </div>
        ))}
        <div className="text-right">
          <button className="button button-primary">add</button>
        </div>
      </PrivateRoute>
    </div>
  );
};

export default Page;
